<template>
  <div>
    <b-button @click="telecharger">
      Manifeste n° {{ manifeste.numero_manif }} (
      {{ manifeste.status == "0" ? "Non receptionné" : "Receptionné" }} )
    </b-button>

    <div id="printDiv" class="mx-2 pt-2">
      <b-row class="d-flex">
        <b-col cols="4" class="d-flex align-center">
          <img class="logo" :src="entete.logo_url" />
        </b-col>
        <b-col cols="5"></b-col>
        <b-col
          cols="3"
          id="logo"
          class="
            gauche
            d-flex
            flex-column
            justify-center
            align-center
            pt-3
            pb-3
          "
        >
          <div>
            <h2 class="font-weight-black">{{ entete.name }}</h2>
          </div>
          <div>RCCM : {{ entete.rccm }}</div>
          <div>{{ entete.address }} - {{ entete.telephone }}</div>
        </b-col>
      </b-row>

      <b-table
        class="mt-5"
        responsive
        :fields="fieldsSociete"
        :items="societes"
        size="lg"
      >
        <template #cell(importateur)="data">
          {{ importateur.name }}
        </template>
        <template #cell(navire)="data">
          {{ navire.name }}
        </template>
        <template #cell(manifeste)="data">
          {{ manifeste.date_arrivee }}
        </template>
      </b-table>

      <b-table
        class="mt-5"
        responsive
        :fields="fieldsVehicules"
        :items="vehicules"
        size="lg"
      >
        <template #cell(prix_carte_imp)="data">
          {{ $thousandSeparator(data.item.prix_carte_imp) }}
        </template>
      </b-table>

      <b-row></b-row>
      <div style="bottom: 0; font-size: 10px; opacity: 0.5">
        Edité par FlashCar
      </div>
    </div>
  </div>
</template>

<script>
import {
  BButton,
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  BPagination,
  BBadge,
  BFormSelect,
  BFormCheckbox,
  BRow,
  BCol,
  BContainer,
  BTable,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BAlert, BLink } from "bootstrap-vue";

export default {
  components: {
    BCardCode,
    BButton,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BFormCheckbox,
    BRow,
    BCol,
    BBadge,
    BContainer,
    BTable,
    ValidationProvider,
    ValidationObserver,
    BAlert,
    BLink,
  },
  data() {
    return {
      manifesteInfos: [],
      vehicules: [],
      societe: {},
      fournisseur: {},
      chargeur: {},
      navire: {},
      manifeste: [],
      idManifeste: "",
      nbr_voitures_vendues: 0,
      fieldsVehicules: [
        { key: "marque", label: "Marque" },
        { key: "couleur", label: "Couleur" },
        { key: "numero_chassis", label: "Chassis" },
        { key: "annee", label: "Annee" },
        { key: "prix_carte_imp", label: "Carte" },
      ],

      fieldsSociete: [
        { key: "name", label: "Nom" },
        { key: "navire", label: "Navire" },
        { key: "manifeste", label: "Date arrivee" },
        { key: "importateur", label: "Importateur" },
      ],

      fournisseurs: [],
      societes: [],
      navires: [],
      chargeurs: [],
      entete: {},
    };
  },
  mounted() {
    this.fournisseursData();
    this.chargeursData();
    this.naviresData();
    this.enteteData();
    this.manifesteData();
  },

  computed: {
    checkManifesteBenefice() {
      let b = 0;
      this.vehicules.forEach((element) => {
        b += element.benefice;
      });

      return b;
    },

    totalPrixVente() {
      let total_dep = 0;
      if (this.vehicules) {
        this.vehicules.forEach((vehicule) => {
          total_dep += vehicule.prix_vente;
        });
      }
      return total_dep;
    },

    totalAchats() {
      let total_achat = 0;
      if (this.vehicules) {
        this.vehicules.forEach((vehicule) => {
          total_achat += vehicule.total_achat;
        });
      }
      return total_achat;
    },
  },

  methods: {
    telecharger() {
      window.print();
      window.close();
    },

    enteteData() {
      this.$http.get("/societes").then((response) => {
        // this.societes = response.data.data[0];
        this.entete = response.data.data[0];
      });
    },

    // get the manifest
    async manifesteData() {
      let id = window.sessionStorage.getItem("manifeste_id");
      await this.$http
        .get("/carte-importateur/manifestes/" + id)
        .then((response) => {
          this.manifeste = response.data.data;
          this.vehicules = this.manifeste.vehicules;
          this.navire = this.manifeste.navire;
          this.societes.push(this.manifeste.societe);
          this.importateur = this.manifeste.importateur;
          this.chargeur = this.manifeste.chargeur;
        });
    },

    //     get all Fournisseurs
    async fournisseursData() {
      let response = await this.$http.get("/fournisseurs").then((response) => {
        this.fournisseurs = response.data.data;
      });
    },

    //     get all Chargeurs
    async chargeursData() {
      let response = await this.$http.get("/chargeurs").then((response) => {
        this.chargeurs = response.data.data;
      });
    },

    //     get all Navires
    async naviresData() {
      let response = await this.$http.get("/navires").then((response) => {
        this.navires = response.data.data;
      });
    },
  },
};
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/pages/ui-feather.scss";

.b-data-table__wrapper table,
.b-data-table__wrapper table tbody tr td,
.b-data-table__wrapper table thead tr td {
  border: 1.2px solid rgb(27, 26, 26) !important;
}

.br {
  border-right: 1.2px dashed rgb(27, 26, 26) !important;
}
// .bl {
// 	border-left: 1.2px solid rgb(27, 26, 26) !important;
// }

@page {
  size: landscape;
}

.col-5 {
  padding: 0px 12px !important;
}

.b-application--wrap {
  min-height: 80vh !important;
}

.invoice-box {
  padding: 0%;
  font-size: 16px;
  line-height: 24px;
  color: #555;
  //   margin: 10px auto !important;
}

@media only screen and (max-width: 1000px) {
  .block-text {
    min-width: 400px;
  }

  .img {
    padding-top: 200px !important;
  }
}

.parent {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.block-text {
  z-index: 2;
}

.child1 {
  position: absolute;
  .img {
    width: 100% !important;
    height: 100% !important;
    overflow: hidden !important;
  }
  z-index: 1;
}

.child2 {
  right: 0;
  margin-top: 10px !important;
  padding-right: 20px !important;
  font-size: 1.5em;
  font-weight: bold;
}

.child3 {
  top: 100px;
  z-index: 1;
  font-size: 4em !important;
  // margin: auto 50px !important;
  border-radius: 10px;
}

#title1 {
  font-size: 20px;
  font-weight: 400;
}

#facturetitle {
  font-size: 30px;
}

#scope1 {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 15px 0 4px 0;
  display: flex;
  justify-content: space-between;
  font-size: 1.3em;
}

#tableau_avertissement .b-data-table__wrapper table tr td {
  font-size: 0.8em !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

#societe {
  padding: 0px !important;
  margin: 0px !important;
}

.b-data-table > .b-data-table__wrapper > table > tbody > tr > td,
.b-data-table > .b-data-table__wrapper > table > thead > tr > td {
  height: 1px !important;
  /* width: 10% !important; */
}

.col-3,
.col-9 {
  padding: 0px !important;
}

#vue_globale {
  width: 100% !important;
}

.logo {
  /* min-width: 150px !important;
			max-width: 150px !important; */

  width: 40%;
  max-width: 150px;
  height: auto;
}

.b-application p {
  padding: 0px !important;
  margin: 0px !important;
}

#texte {
  font-size: 12px !important;
}
</style>